$(function(){
  // グロナビ メニューボタン
  $('.nav-trigger').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).next('.gnav').removeClass('open');
      $('.overlay').removeClass('open');
    } else {
      $(this).addClass('active');
      $(this).next('.gnav').addClass('open');
      $('.overlay').addClass('open');
    }
  });
  $('.overlay').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.nav-trigger').removeClass('active');
      $('.gnav').removeClass('open');
      $('.gnav ul .pulldown').removeClass('spread');
    }
  });
  $('.gnav .nav-search .btn').on('click',function(){
    $('.gnav ul .pulldown').removeClass('spread');
  });
  $('.gnav > ul > li > span').on('click',function(){
    if($('+ .pulldown',this).hasClass('spread')){
      $(this).next().removeClass('spread');
      $('.turn').removeClass('turn');
      $('.overlay').addClass('open');
    } else {
      $('.gnav > ul > li > span + .spread').removeClass('spread');
      $('.turn').removeClass('turn');
      $(this).next().addClass('spread');
      $(this).addClass('turn');
      $('.overlay').addClass('open');
    }
  });
  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
  }
  $(window).on("load scroll", displayToTop);
  // English url
  var pageUrl = location.href;
  $('.menu-en').attr('href','https://translate.google.co.jp/translate?hl=&sl=ja&tl=en&u=' + pageUrl);

  if ($('body').hasClass('home')) {
    // features
    //slickスライダー初期化
    $("#feature .slider").slick({
      autoplay:true,
      arrows: false,
      autoplaySpeed:5000,
      pauseOnHover: false,
      speed: 1005,
      slidesToShow:1,
      slidesToScroll:1,
      centerMode: true,
      centerPadding:'0',
      infinite: true
    });
  }
});

function selectChange(){
  var clickSelectId = $('.selects :radio:checked').parent('label').attr("id").split('select')[1];
  console.log(click2SelectId);
  // $('.entry').load('/'+click2SelectId+'.html #entry'+click2SelectId);
}

$(window).on('load resize',function(){
  // フッター メニュー開閉
  if ($(window).width() < 992) {
    $('.guide h3').on('click', function(){
      $(this).next().toggle();
    });
    $('.nav-search').insertBefore('.nav-index');
  } else {
    $('.nav-search').insertAfter('.nav-about');
  }
});
$(window).on('load', function() {
  if ($(window).width()<992) {
    var headerHeight = 80; // スマホのヘッダー等の高さ分の数値を入れる
  } else {
    var headerHeight = 140; // PC のヘッダー等の高さ分の数値を入れる
  }
  var url = $(location).attr('href');
    if(url.indexOf("#") != -1){
      var anchor = url.split("#");
      var target = $('#' + anchor[anchor.length - 1]);
      if(target.length){
      var pos = Math.floor(target.offset().top) - headerHeight;
      $("html, body").animate({scrollTop:pos}, 500);
    }
  }
});

$(function(){
  $('.effect div').css("opacity","0");
  $(window).scroll(function (){
    $(".effect").each(function(){
      var imgPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + windowHeight/10){
        $("div",this).css("opacity","1" );
      } else {
        $("div",this).css("opacity","0" );
      }
    });
  });
});

$(window).on("load resize scroll", function(e){
  e.preventDefault();
  var scrollTop2 = $(window).scrollTop();
  // if ($(window).width() > 992) {
  //   var headlineHight = 80+100;
  // } else {
  //   var headlineHight = 145;
  // }
  var headlineHight = $('#contents').offset().top + 30;
  // console.log(scrollTop2+', '+ headlineHight);
  if (scrollTop2 > headlineHight) {
    $('.header').addClass('floatingNav');
    $('.menu').appendTo('.gnav');
    $('.nav-trigger').css('right',0);
  } else {
    $('.floatingNav').removeClass('floatingNav');
    $('.menu').appendTo('.header-menu');
    var menuRight = $('.logo').offset();
    $('.nav-trigger').css('right', menuRight.left+'px');
  }
  if ($(window).width() < 992) {
    $('.menu').appendTo('.header-menu');
  }
});
